export default [{
	"name": "浦海蓓",
	"img": "./img/浦海蓓.jpeg"
}, {
	"name": "于迪",
	"img": "./img/于迪.jpeg"
}, {
	"name": "李耀健",
	"img": "./img/李耀健.jpeg"
}, {
	"name": "伊泽",
	"img": "./img/伊泽.jpeg"
}, {
	"name": "贾忠良",
	"img": "./img/贾忠良.jpeg"
}, {
	"name": "吴文德",
	"img": "./img/吴文德.jpeg"
}, {
	"name": "刘涛",
	"img": "./img/刘涛.jpeg"
}, {
	"name": "丁香",
	"img": "./img/丁香.jpeg"
}, {
	"name": "张伟斌",
	"img": "./img/张伟斌.jpeg"
}, {
	"name": "赵常余",
	"img": "./img/赵常余.jpeg"
}, {
	"name": "黄飞虎",
	"img": "./img/黄飞虎.jpeg"
}, {
	"name": "杨其凡",
	"img": "./img/杨其凡.jpeg"
}, {
	"name": "王金双",
	"img": "./img/王金双.jpeg"
}, {
	"name": "杨星",
	"img": "./img/杨星.jpeg"
}, {
	"name": "牛振杰",
	"img": "./img/牛振杰.jpeg"
}, {
	"name": "姜国腾",
	"img": "./img/姜国腾.jpeg"
}, {
	"name": "高伟龙",
	"img": "./img/高伟龙.jpeg"
}, {
	"name": "姜磊",
	"img": "./img/姜磊.jpeg"
}, {
	"name": "吴雪平",
	"img": "./img/吴雪平.jpeg"
}, {
	"name": "黄娇",
	"img": "./img/黄娇.jpeg"
}, {
	"name": "王珑珑",
	"img": "./img/王珑珑.jpeg"
}, {
	"name": "王楠",
	"img": "./img/王楠.jpeg"
}, {
	"name": "崔磊",
	"img": "./img/崔磊.jpeg"
}, {
	"name": "彭峰",
	"img": "./img/彭峰.jpeg"
}, {
	"name": "刘梦飞",
	"img": "./img/刘梦飞.jpeg"
}, {
	"name": "张志帅",
	"img": "./img/张志帅.jpeg"
}, {
	"name": "赵文凯",
	"img": "./img/赵文凯.jpeg"
}, {
	"name": "康岩",
	"img": "./img/康岩.jpeg"
}, {
	"name": "汪双发",
	"img": "./img/汪双发.jpeg"
}, {
	"name": "王晨",
	"img": "./img/王晨.jpeg"
}, {
	"name": "刘卫彪",
	"img": "./img/刘卫彪.jpeg"
}, {
	"name": "李翔",
	"img": "./img/李翔.jpeg"
}, {
	"name": "金燕燕",
	"img": "./img/金燕燕.jpeg"
}, {
	"name": "肖德超",
	"img": "./img/肖德超.jpeg"
}, {
	"name": "李小静",
	"img": "./img/李小静.jpeg"
}, {
	"name": "刘静",
	"img": "./img/刘静.jpeg"
}, {
	"name": "宋晓兵",
	"img": "./img/宋晓兵.jpeg"
}, {
	"name": "李灵芳",
	"img": "./img/李灵芳.jpeg"
}, {
	"name": "汪少俊",
	"img": "./img/汪少俊.jpeg"
}, {
	"name": "刘璐",
	"img": "./img/刘璐.jpeg"
}, {
	"name": "秘博阳",
	"img": "./img/秘博阳.jpeg"
}, {
	"name": "高景明",
	"img": "./img/高景明.jpeg"
}, {
	"name": "张前",
	"img": "./img/张前.jpeg"
}, {
	"name": "杨跃",
	"img": "./img/杨跃.jpeg"
}, {
	"name": "王信",
	"img": "./img/王信.jpeg"
}, {
	"name": "王玉梁",
	"img": "./img/王玉梁.jpeg"
}, {
	"name": "肖庆",
	"img": "./img/肖庆.jpeg"
}, {
	"name": "胡一江",
	"img": "./img/胡一江.jpeg"
}, {
	"name": "王静",
	"img": "./img/王静.jpeg"
}] 